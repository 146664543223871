import React from 'react';
import PropertyDetailHeader from './PropertyDetailHeader.tsx';
import PropertyDetailRightSticky from './PropertyDetailRightSticky.tsx';

import ScrollSpyComponent from './ScrollSpyComponent.js';
import PropertyDetailLeftCol from './PropertyDetailLeftCol.tsx';


function PropertyDetail() {


  return (
    <>
      <PropertyDetailHeader/>

      <div className='w-1200 mx-auto my-4'>
        <div className='row g-3'>
          <div className='col-md-9'>
          <PropertyDetailLeftCol/>
            
          </div>

          <div className='col-md-3'>
            <PropertyDetailRightSticky/>            
          </div>

          <div className='col-md-12'>

          </div>
        </div>
      </div>
    </>
  )
}

export default PropertyDetail;
