import React from 'react';
import ListedProjectSlider from './ListedProjectSlider';

function Listedprojects() {
  return (
    <>
    <div className='bg-projects py-5'>
      <div className='container'>
      <ListedProjectSlider title="Actors" subtitle="Exclusive Actors of top talent" desktop={4}/>
      </div>
        
    </div>

    </>
  )
}

export default Listedprojects;
