import React from 'react';
import logoImg from '../images/main-logo.png';

import Navigation from './Navigation.js';
import FormPopUp from './FormPopUp.js';
import FormRegisterPopUp from './FormRegisterPopUp.js';


function Header() {
  

  return (
    <>
    {/* <div className='headertop py-1 py-sm-3'>
        <div className='container_notify'>
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
  
  <div className="carousel-inner text-white px-5 px-sm-0">
    <div className="carousel-item active">      
        <div className='row g-2 justify-content-center align-items-center'>
          <div className='col-sm-auto'>Some representative placeholder content for the first slide. <button className='btn-click d-inline-block d-sm-none py-0 px-2'>Click Here</button></div>
          <div className='col-sm-auto'><button className='btn-click d-none d-sm-inline-block'>Click Here</button></div>
       </div>
    </div>
    <div className="carousel-item">
    <div className='row g-2 justify-content-center align-items-center'>
          <div className='col-sm-auto'>Some representative placeholder content for the first slide. <button className='btn-click d-inline-block d-sm-none py-0 px-2'>Click Here</button></div>
          <div className='col-sm-auto'><button className='btn-click d-none d-sm-inline-block'>Click Here</button></div>
       </div>       
    </div>
    <div className="carousel-item">
    <div className='row g-2 justify-content-center align-items-center'>
          <div className='col-sm-auto'>Some representative placeholder content for the first slide. <button className='btn-click d-inline-block d-sm-none py-0 px-2'>Click Here</button></div>
          <div className='col-sm-auto'><button className='btn-click d-none d-sm-inline-block'>Click Here</button></div>
       </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
        </div>
    </div> */}
    
    <header className='position-static'>
      <div className="container-fluid headerTop py-1 px-sm-3">
          <div className="w-1200 mx-auto" data-aos="fade-top" data-aos-delay="200">
              <nav className="navbar navbar-expand-xl pe-0">
                  <div className="w-100">
                      <div className='row g-3 align-items-center'>
                          <div className='col-auto' data-aos="fade-right" data-aos-delay="200">
                              <a className="navbar-brand" href="/" ><img src={logoImg} alt='univariety' width={200} className='img-fluid'/></a>
                          </div>

                          <div className='col-xl-auto col-md col  d-none d-sm-flex justify-content-end  order-xl-1'>
                                <FormPopUp className='btn btn-login me-2 border-0' name="Login"/>
                                <FormRegisterPopUp className='btn btn-register border-0' name="Register"/>
                                
                          </div>

                          <Navigation/>                      
                      
                      </div>
                  </div>
              </nav>
              
          </div>
      </div>      
    </header>
    </>
    
  )
}

export default Header;
