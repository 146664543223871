import React from 'react';

function PropertyDetailHeader() {
  return (
    <>
      <div className='container-fluid py-4 bg-danger'>
      PropertyDetailHeader
      </div>
    </>
  )
}

export default PropertyDetailHeader;
