import React from 'react';

function PropertyDetailRightSticky() {
  return (
    <>
      <div className='sticky-top'>
              <div className='row g-3'>
                <div className='col-12'>
                  <div className='card p-4'>
                    <h2 className='font-24 font-500 text-start'>Contact Owner</h2>
                    <div className='phone-number font-18 font-500 '>SRINIVAS <span className='font-16 font-400 text-gray06'>+91-93XXXXXXXX</span></div>
                    <div className='mt-2'>
                      <button className='btn btn-red'>Get Phone No.</button>
                    </div>
                  </div>
                </div>

                <div className='col-12'>
                  <div className='card p-4 text-start'>
                    <button className='btn border-0 m-0 p-0 text-start font-18 font-500'><i className="fa-solid fa-file-arrow-down me-2 font-22"></i> Download Brochure</button>
                  </div>
                </div>
              </div>
            </div>
    </>
  )
}

export default PropertyDetailRightSticky;
