import React from 'react';

import schoolsImg from '../../images/schools.png';
import universitiesImg from '../../images/universities.png';
import counsellorsImg from '../../images/counsellors.png';

import VideoModalPopup from '../VideoModalPopup';

function Partnerspopup() {

  return (
    <>
    <div className="row g-4">
                    <div className="col-lg-4 col-md-6 col-sm-6" >
                       <div className="partnersBlock schools" data-aos="fade-left" data-aos-delay="200">
                            <div className="partnersBlockImg">
                            <VideoModalPopup className="btn btn-play video-btn" videoId ='8CPn51JioUk'/>
                                <a href='#' ><img  src="https://i.ytimg.com/vi/8CPn51JioUk/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLAwc5w45TbfG0JVpKOOfQPEJ6mTVg" width="100%" className="img-fluid" alt=""/></a>
                                
                            </div>
                            {/* <div className="partnersBlockTitle">
                                <div className="TitleDetail">
                                    <h4></h4>
                                    <p></p>
                                </div>
                                
                                <VideoModalPopup className="btn btn-play video-btn" videoId ='8CPn51JioUk'/>
                                
                            </div> */}
                       </div> 
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="partnersBlock universities" data-aos="fade-left" data-aos-delay="400">
                             <div className="partnersBlockImg">
                             <VideoModalPopup className="btn btn-play video-btn" videoId ='D66SSJ3Dj5Y'/>
                                <img src='https://i.ytimg.com/vi/D66SSJ3Dj5Y/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBF2FHs5e7a-xlbREeiqRgfazGQew' width="100%" className="img-fluid" alt=""/>
                             </div>
                             {/* <div className="partnersBlockTitle">
                                 <div className="TitleDetail">
                                     <h4>rrr</h4>
                                     <p>rrr</p>
                                 </div>
                                 
                                 <VideoModalPopup className="btn btn-play video-btn" videoId ='D66SSJ3Dj5Y'/>
                             </div> */}
                        </div> 
                     </div>
                     <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="partnersBlock counsellors" data-aos="fade-left" data-aos-delay="600">
                             <div className="partnersBlockImg">
                             <VideoModalPopup className="btn btn-play video-btn" videoId ='D66SSJ3Dj5Y'/>
                                <img src="https://i.ytimg.com/vi/Yzr4PEdPATI/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLDW35cBdD6R8m-V2RlB5HjDh8h6oQ" width="100%" className="img-fluid" alt=""/>
                             </div>
                             {/* <div className="partnersBlockTitle">
                                 <div className="TitleDetail">
                                     <h4></h4>
                                     <p></p>
                                 </div>
                                 
                               <VideoModalPopup className="btn btn-play video-btn" videoId ='Yzr4PEdPATI'/>
                             </div> */}
                        </div> 
                     </div>
                </div>
      


    </>
  );
}

export default Partnerspopup;