import React from 'react';

import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import imgPayRent from '../../images/pay-rent.svg';
import imgPersonalLoan from '../../images/personal-loan.svg';
import imgQuickCash from '../../images/quick-cash.svg';
import imgRentNowPayLater from '../../images/rent-now-pay-later.svg';
import imgRentAgreement from '../../images/rent-agreement.svg';
import imgHomeLoans from '../../images/home-loans.svg';
import imgHomeInteriors from '../../images/home-interiors.svg';
import imgPropertyManagement from '../../images/property-management.svg';
import imgFixedDeposit from '../../images/fixed-deposit.svg';
import imgActors from '../../images/drama.svg';
import imgProducers from '../../images/producer.svg';
import imgDirector from '../../images/director-chair.svg';
import imgMusicDirector from '../../images/guitar.svg';
import imgDubbingArtist from '../../images/dubbing.svg';
import imgTechnicians from '../../images/technician.svg';
import imgArtDirector from '../../images/artist.svg';
import imgCameraMan from '../../images/camera-operator.svg';
import imgEditor from '../../images/content.svg';
import imgVfx from '../../images/designer.svg';
import imgLocations from '../../images/map.svg';
import imgBrandPromotion from '../../images/businessman.svg';
import imgFilmPromotion from '../../images/video-marketing.svg';
import imgWriters from '../../images/writer.svg';
import imgTheatres from '../../images/theater.svg';
import imgEquipment from '../../images/equipment.svg';
import imgAwards from '../../images/trophy.svg';
import imgCelebrities from '../../images/celebration.svg';


function Whychooseblock() {

    const options = {
        merge: true,
        loop: false,
        video: true,
        lazyLoad: true,
        autoWidth:true,
        nav: true,
        dots: false,
        margin: 20,
     
    };
  return (
    <>
      <div className="why_choose_block text-white pt-5 pb-5" >
            <div className="container">
                <h2 className="font-30 font-400 mb-4 pb-2 text-start">Our  <span className="font-500">Categories</span></h2>
                <div className=" d-flex justify-content-between flex-wrap">
                    <OwlCarousel className="owl-carousel owl-theme regularCarousel w-100" id="Kasdealscarousel" {...options}>
                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgActors} alt="Actors" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Actors</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgProducers} alt="Producers" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Producers</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgDirector} alt="Director" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Director</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgMusicDirector} alt="Music Director" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Music Director</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgDubbingArtist} alt="Dubbing Artist" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Dubbing Artist</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgTechnicians} alt="Technicians" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Technicians</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgArtDirector} alt="Art Director" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Art Director</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgCameraMan} alt="Camera man" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Camera man</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgEditor} alt="Editor" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Editor</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgVfx} alt="VFX" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">VFX</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgLocations} alt="Locations" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Locations</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgBrandPromotion} alt="Brand Promotion" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Brand Promotion</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgFilmPromotion} alt="Film Promotion" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Film Promotion</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgWriters} alt="Writers" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Writers</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgTheatres} alt="Theatres" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Theatres</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgEquipment} alt="Equipment" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Equipment</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgAwards} alt="Awards" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Awards</span>
                            </a>
                        </div>

                        <div className="item">
                            <a className="item-block" href="#">
                                <span className="item-icon-wrap">
                                    <img src={imgCelebrities} alt="Celebrities" width={40} height={40} className="item-icon" />
                                </span>
                                <span className="item-name">Celebrities</span>
                            </a>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        </div>
    </>
  )
}

export default Whychooseblock;
