import React from 'react';



import Homeslider from './Homeslider';
import Partnerspopup from './MyVerticallyCenteredModal';
import GlobalSlider from '../GlobalSlider';
import Bannerslider from './Bannerslider';
import Whychooseblock from './Whychooseblock';
import Countblock from './Countblock';
import Listedprojects from './Listedprojects';
import SingleAdSlider from '../SingleAdSlider';
import SearchProperty from './SearchProperty.tsx';
import WelcomeSlider from './WelcomeSlider.js';

function Home(props) {

  return (
    <>

        <section className="headerBanner custom_dark_banner" id="headerBanner">
            <div className="">
                <Bannerslider/>
            </div>
        </section>

        <section className="container-fluid insights" id="insights" data-aos="fade-up" data-aos-delay="200">
            <div className="w-1200 mx-auto">
                

                <div className='row g-5 align-items-center'>
                    <div className='col-lg-8'>
                        <h2 className='mb-0 font-30 font-600'>Welcome back! </h2>
                        <p>Let’s continue your search</p>
                        {/* <div className='xscroll mb-2'>
                            <ul className="nav nav-pills align-items-center search-tab flex-nowrap" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-buy-tab" data-bs-toggle="pill" data-bs-target="#pills-buy" type="button" role="tab" aria-controls="pills-buy" aria-selected="true">Buy</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-rent-tab" data-bs-toggle="pill" data-bs-target="#pills-rent" type="button" role="tab" aria-controls="pills-rent" aria-selected="false">Rent</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-new-projects-tab" data-bs-toggle="pill" data-bs-target="#pills-new-projects" type="button" role="tab" aria-controls="pills-new-projects" aria-selected="false">New Projects</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-pg-tab" data-bs-toggle="pill" data-bs-target="#pills-pg" type="button" role="tab" aria-controls="pills-pg" aria-selected="false">PG</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-plot-tab" data-bs-toggle="pill" data-bs-target="#pills-plot" type="button" role="tab" aria-controls="pills-plot" aria-selected="false">Plot</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-commercial-tab" data-bs-toggle="pill" data-bs-target="#pills-commercial" type="button" role="tab" aria-controls="pills-commercial" aria-selected="false">Commercial</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                <a className="nav-link" href='javascript:;'>Post Free Property AD</a>
                                </li>
                            </ul>
                        </div> */}
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-buy" role="tabpanel" aria-labelledby="pills-buy-tab">
                                <SearchProperty/>
                            </div>
                            {/* <div className="tab-pane fade" id="pills-rent" role="tabpanel" aria-labelledby="pills-rent-tab"> <SearchProperty/></div>
                            <div className="tab-pane fade" id="pills-new-projects" role="tabpanel" aria-labelledby="pills-new-projects-tab"> <SearchProperty/></div>
                            <div className="tab-pane fade" id="pills-pg" role="tabpanel" aria-labelledby="pills-pg-tab"> <SearchProperty/></div>
                            <div className="tab-pane fade" id="pills-plot" role="tabpanel" aria-labelledby="pills-plot-tab"> <SearchProperty/></div>
                            <div className="tab-pane fade" id="pills-commercial" role="tabpanel" aria-labelledby="pills-commercial-tab"> <SearchProperty/></div> */}
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <WelcomeSlider desktoplg={1} desktopmd={1} tab={1} mobile={1} dots={true} nav={false}/>                      
                    </div>
                </div>


                

            
            </div>
        </section>

        <Countblock/>

        <section className="container-fluid insights" id="insights" data-aos="fade-up" data-aos-delay="200">
            <div className="w-1200 mx-auto">
                <h2 className='font-30 font-500 mb-0'>Latest Blogs<br className="d-none d-md-block" /></h2>
                <p>View the latest blogs list of new Movies, Banners, News, Articles, etcs  </p>

               

                <Homeslider desktoplg={4} desktopmd={3} tab={2} mobile={1} />


                <div className="d-flex justify-content-center mt-4 pt-3">
                    <a href="/" className="btn-insights">VIEW ALL INSIGHTS</a>
                </div>
                {/* <div className='mt-5 text-center'>                    
                    <SingleAdSlider add1="../../images/profiles/flim01.jpeg" />
                </div> */}

            </div>
        </section>

        <section className="container-fluid headerBanner custom_search py-5 d-none" id="custom_search">
            <div className="custom_search_wrap mx-auto">
                {/* <div className='custom_search_tab'>
                        <a href="#">Buy</a><a href="#">Rent</a><a href="#">Commercial</a>
                </div> */}
                {/*<div className='custom_search_form'>
                     <div className='select_input'>
                        <select className="form-select" aria-label="Default select example">
                            <option selected="">Select Catogery</option>
                            <option value="1">Actor</option>
                            <option value="2">Producers</option>
                        </select>
                        <div className="input-group">                       
                            <input type="text" aria-label="First name" className="form-control"/>
                            <button className='btn btn-submit'> <i className="bi bi-search"></i> Search</button>
                        </div>
                    </div> */}
                    {/*<div className='p-3'>
                     <div className='row g-3 align-items-center'>
                         <div className='col-auto'>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="FullHouse"/>
                                <label className="form-check-label" for="FullHouse">
                                    Full House
                                </label>
                            </div>
                        </div> 
                         <div className='col-auto'>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="Hostel"/>
                                <label className="form-check-label" for="Hostel">
                                    PG / Hostel
                                </label>
                            </div>
                        </div>
                        <div className='col-auto'>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="Flatmates"/>
                                <label className="form-check-label" for="Flatmates">
                                    Flatmates
                                </label>
                            </div>
                        </div>
                        <div className='col-auto'>
                        <select className="form-select" aria-label="Default select example">
                            <option selected="">Select City</option>
                            <option value="1">Banglore</option>
                            <option value="2">Hyderabad</option>
                        </select>
                        </div>
                        <div className='col-auto'>
                        <select className="form-select" aria-label="Default select example">
                            <option selected="">Select City</option>
                            <option value="1">Banglore</option>
                            <option value="2">Hyderabad</option>
                        </select>
                        </div> 
                    </div> 
                    </div>*/}
                {/* </div>
                <div className='mt-5 text-center'>                    
                    <SingleAdSlider add1="https://tpc.googlesyndication.com/simgad/8730389779908098243?" />
                </div> */}
            </div>
        </section>

        <Whychooseblock/>        

        <Listedprojects/>

        {/* <section className="container-fluid py-5 career_guidance_program_banner" id="Featured" data-aos="fade-up" data-aos-delay="200">
            <div className="w-1200 mx-auto py-0" >
                <h2 className=''>Featured List</h2>
                <p className=' mb-5'>This is a special feature of CCC where, the registered members who are benefited through CCC, will be given a privilege to be featured exclusively. The Database of the member will also be automatically updated by CCC and be highlighted.</p>
                <GlobalSlider title="FeaturedList" margin={20} autoplay="autoplay" mobilecount={1} tabminicount={2} tabcount={2} desktopcount={4} mobiledots="true" tabminidots="true" tabdots="true"/>
                <div className='mt-5 text-center'>                    
                    <SingleAdSlider add1="https://tpc.googlesyndication.com/simgad/8730389779908098243?" />
                </div>
              
            </div>
        </section> */}

        <section className="container-fluid partners" id="partners" data-aos="fade-up" data-aos-delay="200">
            <div className="w-1200 mx-auto">
                <h2 className='font-30 font-500 mb-4'>Video Linked with us</h2>
                {/* <p>350+ top schools have chosen Univariety for alumni networking & career guidance solutions</p> */}
                
                <Partnerspopup/>
            </div>
        </section>

    </>
  )
}

export default Home;
