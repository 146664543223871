import React from 'react';

import RightImg from '../../../images/right-img.jpg';
import LeftImg1 from '../../../images/ee8dabebf5fb0fd88120e1f66eb5d95a@2x.png';
import LeftImg2 from '../../../images/added-img-lg.png';
import RecentSuccessStoriesImg1 from '../../../images/image@2x.png';
import RecentSuccessStoriesImg2 from '../../../images/image-1@2x.png';
import RecentSuccessStoriesImg3 from '../../../images/image-2@2x.png';
import SingleColForm from '../../SingleColForm';

function BlogTemplate2() {
  return (
    <>
 

        <div className="bg-yellow container-fluid white-element pt-5">
            <div className="row position-relative z-2 realGullyBoy">
            
                <div className="col-xxl-6 col-xl-5 col-lg-5 col-md-5">
                    <div className="row justify-content-end h-100 align-items-center">
                        <div className="col-xxl-8 col-xl-10 col-lg-11 col-md-12">
                            <div className="pe-md-4 ps-4 ps-lg-0">
                                <h1>The Real<br className="d-none d-xl-block"/> Gully Boy</h1>
                                <div className="sub-title">Five Star Chef From Dharavi - An Alumni Story</div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id.</p>
                                <a className="btn btn-white" href="javascript:;" data-bs-toggle="modal" data-bs-target="#downloadForm">CTA Button <span className="right-arrow"></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-6 col-xl-7 col-lg-7 col-md-7">
                    <div className="row">
                        <div className="col-xl-11">
                            <div className="left-dot" data-img="796px * 748px">
                                <img src={RightImg} className="img-fluid" width="893"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-white container-fluid py-100">
            <div className="container max-1140 blog-detail-page">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="reg_bg">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="red_bg_text">
                                        WANT TO DISCOVER YOUR OWN ALUMNI STORIES
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-lg-auto order-lg-1 position-relative z-1">
                        <div className="rightSideForm rightGraph mt-min-lg-250" id="rightSideForm">
                            <form className="" name="user_form" id="user_form" action="" method="post">
                
              
                                <div className="row g-3 align-items-center">
                                    <div className="col-md-12">
                                        <h3>Book A Demo</h3>
                                        <p className="text-start font-14 font-500 mb-0">* Marked fields are mandatory</p>
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" className="form-control flex-1" placeholder="Name*" name="FirstNamestd" id="FirstNamestd"/>
                                        <div className="error-feedback" id="FirstNamestdsp"></div>
                                    </div>
                                 
                                   
                                    <div className="col-md-12">
                                        <input type="email" className="form-control flex-1" placeholder="Email ID*" name="EmailAddressstd" id="EmailAddressstd"/>
                                        <div id="EmailAddressstdsp" className="error-feedback"></div>
            
                                    </div>
                                    
                                    <div className="col-md-12">
            
                                        <input type="text" className="form-control flex-1" placeholder="Contact Number*" name="ContactNumberstd" id="ContactNumberstd"/>
                                        <div id="ContactNumberstdsp" className="error-feedback"></div>
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" className="form-control flex-1" placeholder="School Name*" name="ParentNamestd" id="ParentNamestd"/>
                                        <div id="ParentNamestdsp" className="error-feedback"></div>
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" className="form-control flex-1" placeholder="City of School*" name="SchoolNamestd" id="SchoolNamestd"/>
                                        <div id="SchoolNamestdsp" className="error-feedback"></div>
                                    </div>
                                    <div className="col-md-12">
                                        <select className="form-select" aria-label="Default select example" onchange="getUserChange()" id="user_type" name="user_type">
                                            <option selected="" value="0">Function Area*</option>
                                            <option value="1">Student</option>
                                            <option value="2">Parent</option>
                                            <option value="3">School</option>
                                            <option value="4">University</option>
                                            <option value="5">Counsellor</option>
                                            <option value="6">Others</option>
                                        </select>
                                        <div id="Selectstdsp" className="error-feedback"></div>                      
                                    </div>
                                    <div className="col-md-12">
            
                                        <textarea className="form-control min-h-95" placeholder="Have Any Thoughts, Share Them with Us" id="floatingTextarea"></textarea>
                                        <div id="ContactNumberstdsp" className="error-feedback"></div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row justify-content-end align-items-center">
            
                                            <div className="col-auto"><button type="button" className="btn btn-cancel" data-bs-dismiss="modal">Cancel</button></div>
                                            <div className="col-auto"><button type="button" className="btn btn-submit" data-bs-target="#success_popup" onclick="return onForm_Submit()">Submit</button></div> 
                                        </div>
                                    </div>
                                </div>
                                
                                
            
                            </form>
                            
                            <SingleColForm/>
                        </div>
                    </div>
                    <div className="col-lg mt-5 mt-lg-0">
                        <div className="page-title me-xxl-5 pe-xxl-5">
                            <h2 className="title mb-2 mb-sm-4">Dinesh Narasimha Kansane, Student of SSRVM, Dharavi</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui. Quisque nec mauris sit amet elit iaculis pretium sit amet quis magna. Aenean velit odio, elementum in tempus ut, vehicula eu diam. </p>
                            <p>Pellentesque rhoncus aliquam mattis. Ut vulputate eros sed felis sodales nec vulputate justo hendrerit. Vivamus varius pretium ligula, a aliquam odio euismod sit amet. Quisque laoreet sem sit amet orci ullamcorper at ultricies metus viverra. Pellentesque arcu mauris, malesuada quis ornare accumsan, blandit sed diam.</p>
                            
                            
                        </div>
                    </div>
                    
                    
                    
                </div>
            </div>
        </div>

        <div className="container-fluid bg-litebeige position-relative">
            <div className="container max-1140 beigeSection">
                <div className="row">
                    <div className="col-12">
                        <h2>Lorem ipsum dolor sit amet</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit,<br className="d-none d-md-block"/> vulputate eu pharetra nec, mattis ac neque.</p>
                    </div>
                    <div className="col-lg-6">
                        <div className="beigeSectionImg text-center mt-4 mt-lg-0" data-img="550px * 450px">
                            <img src={LeftImg1} className="img-fluid" width="550"/>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="pt-4 pt-lg-4 pt-xxl-5 ps-0 ps-lg-4 ps-xxl-5 beigeSectiondetail">                            
                            <ul className="arrow-list mb-4 mb-sm-5">
                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit</li>
                                <li>vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus,</li>
                                <li>vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus</li>
                            </ul>
                            <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#downloadBrochure" className="btn-download-green"><i></i>Download Brochure</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid">
            <div className="row fold-wrap">

               
                  <div className="fold-section p-0">
                
                    <div className="fold-section-ui">
                        <div className="row">
                            <div className="col-md-6" data-img="550px * 450px">
                                <img src={LeftImg2} className="img-fluid" width="550"/>
                            </div>
                       
                            <div className="col-md-6">
                                <h1>Heading Goes Here</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus…</p>
                                <ul className="listed-points">
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur.</li>
                                </ul>
                                <a className="btn-green" href="javascript:;" data-bs-toggle="modal" data-bs-target="#downloadForm">CTA BUTTON</a>
                            </div>
                            
                        </div>
                    </div>
                  </div>
    
            </div>
        </div>

        <div className="bg-white container-fluid">
            <div className="container max-1140 recentSuccessStories">
                <h2>Recent Success Stories</h2>
                <div className="x-scroll">
                    <div className="recentSuccessStoriesList">
                        <div className="recentSuccessList">
                            <div className="listImg" data-img="350px * 249px">
                                <img src={RecentSuccessStoriesImg1} width="100%"/>
                            </div>
                            <div className="listDetail">
                                <h3>Taking A Leaf Out Of the Indigo Airlines Approach</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit nulla quam velit.</p>
                                <div className="date">Aug 3, 2022</div>
                                <a className="btn-read" href="javascript:;">Read More <i></i></a>
                            </div>
                        </div>
    
                        <div className="recentSuccessList">
                            <div className="listImg" data-img="350px * 249px">
                                <img src={RecentSuccessStoriesImg2} width="100%"/>
                            </div>
                            <div className="listDetail">
                                <h3>Taking A Leaf Out Of the Indigo Airlines Approach</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit nulla quam velit.</p>
                                <div className="date">Aug 3, 2022</div>
                                <a className="btn-read" href="javascript:;">Read More <i></i></a>
                            </div>
                        </div>
    
                        <div className="recentSuccessList">
                            <div className="listImg" data-img="350px * 249px">
                                <img src={RecentSuccessStoriesImg3} width="100%"/>
                            </div>
                            <div className="listDetail">
                                <h3>Taking A Leaf Out Of the Indigo Airlines Approach</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit nulla quam velit.</p>
                                <div className="date">Aug 3, 2022</div>
                                <a className="btn-read" href="javascript:;">Read More <i></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-4 pt-3">
                    <a className="btn-viewmore" href="javascript:;">View More <i></i></a>
                </div>
            </div>
        </div>
       
    </>
  )
}

export default BlogTemplate2;
