import React from 'react';

export default function SearchProperty() {
  return (
    <>
      <div className='searchWrap'>
          <div className='inputWrap'>
          <div className='searchInput'><input type='text' placeholder='Enter Name' className='form-control'/></div>
          <div className='searchSelect'>
              <select className="form-select property-type" aria-label="Default select example">
                  <option selected>Select Category</option>
                  <option value="1">Actors</option>
                  <option value="2">Producers</option>
                  <option value="3">Directors</option>
              </select>
          </div>
          <div className='searchSelect'>
              <select className="form-select property-budget" aria-label="Default select example">
                  <option selected>Gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
              </select>
          </div>
          </div>
          <button className='btn btn-search'><i className='icon-search'></i><span>Search</span></button>
      </div>
    </>
  )
}
