import React from 'react';

import img1 from '../../images/blog-thumb-1.png';
import img2 from '../../images/blog-thumb-2.png';
import img3 from '../../images/blog-thumb-3.png';
import img4 from '../../images/blog-thumb-4.png';

function PropertyDetailLeftCol() {
  return (
    <>
      <div className='row g-3'>
        <div className="col-12">
            <div className='card p-4'>
              <div className='row g-3'>
                    <div className='col-12'>
                        <div className='row g-1'>
                            <div className='col'>
                                <div className='row g-2 g-sm-3 align-items-center'>
                                    <div className='col-auto'><h3 className='font-18 font-500 mb-0'>₹58.0 Lac</h3></div>
                                    <div className='col-auto'><a href="javascript:;" className='text-black'>EMI - ₹ 26k</a></div>
                                    <div className='col-auto'><a href="javascript:;" className='text-black'>Need Home Loan? Check Eligibility</a></div>
                                </div>                                
                            </div>
                            <div className='col-auto'>
                                <div className='report_share'>
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                    <ul>
                                        <li><a href="javascript:;">Share Property</a></li>
                                        <li><a href="javascript:;">Report an issue</a></li>
                                    </ul>
                                </div>
                             </div>
                            <div className='col-12'>
                                2 BHK Flat For Sale in Vasathi Anandi, <a href="javascript:;" className='text-black'>Appa junction, Hyderabad</a>
                            </div>
                        </div>
                    </div>                    

                    <div className='col-md-5'>
                        <div className='gallary-section'>
                            <div className='row g-1'>
                                <div className='col-12'>
                                    <a href="javascript:;"><img src={img1} className='img-fluid' alt="" /></a>
                                </div>
                                <div className="col-4"><a href="javascript:;"><img src={img2} className='img-fluid' alt="" /></a></div>
                                <div className="col-4"><a href="javascript:;"><img src={img3} className='img-fluid' alt="" /></a></div>
                                <div className="col-4"><a href="javascript:;"><img src={img4} className='img-fluid' alt="" /></a></div>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-7'>
                        <div className='bg-gray-f5 p-3 rounded overflow-hidden mb-3'>
                                <div className='row g-4'>
                                    <div className="col-auto"><i className="fa-solid fa-bed me-2"></i><span>2Beds</span></div>
                                    <div className="col-auto"><i className="fa-solid fa-bath me-2"></i><span>2Baths</span></div>
                                    <div className="col-auto"><i className="fa-regular fa-building me-2"></i><span>1Balcony</span></div>
                                    <div className="col-auto"><i className="fa-solid fa-couch me-2"></i><span>Semi-Furnished</span></div>
                                </div>
                        </div>
    
                        <div className='row g-3'>
                            <div className='col-md-4'>
                                <div>Carpet Area <span className='font-500 d-block'>862sqft</span><span className='font-500 d-block'>₹6,729/sqft</span></div>
                            </div>

                            <div className='col-md-4'>
                                <div>Developer <span className='font-500 d-block'>Vasathi Housing Ltd.</span></div>
                            </div>

                            <div className='col-md-4'>
                                <div>Project <span className='font-500 d-block'>Vasathi Anandi</span></div>
                            </div>

                            <div className='col-md-4'>
                                <div>Floor <span className='font-500 d-block'>2(Out of 7 Floors)</span></div>
                            </div>

                            <div className='col-md-4'>
                                <div>Transaction type <span className='font-500 d-block'>Resale</span></div>
                            </div>

                            <div className='col-md-4'>
                                <div>Status <span className='font-500 d-block'>Ready to Move</span></div>
                            </div>

                            <div className='col-md-4'>
                                <div>Facing <span className='font-500 d-block'>North - West</span></div>
                            </div>

                            <div className='col-md-4'>
                                <div>Lifts <span className='font-500 d-block'>2</span></div>
                            </div>
                            <div className='col-md-4'>
                                <div>Furnished Status <span className='font-500 d-block'>Semi-Furnished</span></div>
                            </div>


                
    
    
    
    
    
    
    
    
  
                        </div>
                    </div>

                    <div className='col-12'>
                        <hr className='p-0 m-0' />
                    </div>

                    <div className='col-12'>
                        <div className='row g-2 align-items-center'>
                            <div className='col-sm'>
                                <div className='row g-2 '>
                                <div className='col-auto'>
                                    <button className="btn btn-red">Get Phone No.</button>
                                </div>
                                <div className='col-auto'>
                                    <button className="btn btn-red-border">Get Phone No.</button>
                                </div>
                                </div>
                            </div>
                            <div className='col-sm-auto'>
                               <div className='d-flex flex-wrap align-items-center'>
                               <i className="fa-solid fa-user me-2"></i>
                               <span>Last contact made 11 days ago</span>
                               </div>
                            </div>
                        </div>
                    </div>
              </div>
            </div>
        </div>
        <div className="col-12">
            <div className='card p-4'>
              sss
            </div>
        </div>
        <div className="col-12">
            <div className='card p-4'>
              sss
            </div>
        </div>
        <div className="col-12">
            <div className='card p-4'>
              sss
            </div>
        </div>
      </div>
    </>
  )
}

export default PropertyDetailLeftCol;
