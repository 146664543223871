import React from 'react';

import OwlCarousel from 'react-owl-carousel';

import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';

import banner from '../../images/home_page_new_banner.png';
import banner1 from '../../images/banner_1.png';
import banner2 from '../../images/banner_2.png';
import banner3 from '../../images/banner_3.png';

import bannerLg from '../../images/banner-lg.jpg';
import bannerSm from '../../images/banner-sm.jpg';
import bannerMb from '../../images/banner-mb.jpg';


function Bannerslider() {
    const options = {
        items: 1,
        margin: 20,
        loop: false,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true, // Correct property name
        autoplayTimeout: 10000, // Set the speed (in milliseconds)
        stopOnHover: false,
    };
  return (
    
    <>
    <OwlCarousel id='Banner_carousel'  className="bannerSlider owl-carousel owl-theme" {...options}>
        <div className="item">
            {/* <div className="row g-5 align-items-center">
                  <div className="col-md" data-aos="fade-left" data-aos-delay="200">
                      <h1>Ordinary to <span className='d-block mt-3'>Extraordinary</span></h1>
                      <h4 className='text-white fst-bold'>Become a Star</h4>
                      <div className='row g-3 mt-4'>
                        <div className="col-auto"><button  className='btn-bookdemo grdGreenBlue border-0 me-3'  ><span>Subscribe</span></button></div>
                        <div className="col-auto"><button  className='btn-bookdemo grdBlueLavender border-0'  ><span>Search</span></button></div>                        
                      </div>                      
                  </div>
                  <div className="col-md-auto text-center d-none d-md-inline-block">
                      <img src={banner1} className="img-fluid" alt="" data-aos="fade-right" data-aos-delay="200"/>
                  </div>
                </div> 
            <img
      src={bannerLg} // Fallback for browsers that do not support srcset
      srcSet={`${bannerMb} 575w, ${bannerSm} 768w,${bannerLg} 1280w`}
      sizes="(max-width: 575px) 575px, (max-width: 768px) 768px, 1280px"
    />*/}

<picture>
  <source media="(max-width:767px)" srcSet={bannerSm}/>
  <source media="(max-width:575px)" srcSet={bannerMb}/>
  <img src={bannerLg} alt="Flowers" />
</picture>

        </div>

        <div className="item">
          <picture>
            <source media="(max-width:767px)" srcSet={bannerSm}/>
            <source media="(max-width:575px)" srcSet={bannerMb}/>
            <img src={bannerLg} alt="Flowers" />
        </picture>
        </div>


        <div className="item">
          <picture>
            <source media="(max-width:767px)" srcSet={bannerSm}/>
            <source media="(max-width:575px)" srcSet={bannerMb}/>
            <img src={bannerLg} alt="Flowers" />
        </picture>
        </div>
        
                    
     
    </OwlCarousel>

    
    </>
  )
}

export default Bannerslider;
