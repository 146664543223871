import React from 'react';

import ImgNelsonMandela from '../../images/nelson_mandela.png';
import CeoJaideepGupta from '../../images/mr_jaideep_gupta.png';
import MentorMrShomieRanjanSas from '../../images/mr_shomie_ranjan_sas.png';
import ImgAristotle from '../../images/aristotle.jpg';

import InfoEdgeLogo from '../../images/info_edge_logo.jpg';
import ImgUnivarietyCulture from '../../images/univariety_culture_bg.svg';
import TargetArrowImg from '../../images/target_arrow_img.svg';
import TeamMate from './TeamMate';
import VideoModalPopup from '../VideoModalPopup';


function AboutUs() {
  return (
    <>
      <div>AboutUs</div>
    </>
  )
}

export default AboutUs
