import React from 'react';
import CountUp from 'react-countup';

function Countblock() {
  return (
    <>
      <div className="count_block desktop py-0" id="counter">
        <div className="container d-flex justify-content-between flex-wrap py-5" data-aos="fade-up" data-aos-delay="500">
            <div data-animate="false">
                <h2 className="font-39 font-800 text-center love_counter1">
                <CountUp start={0} end={700000} duration={2.5} separator="," />
                    +</h2>
                <p className="font-18 font-500 text-center mb-0">Buyers Associated</p>
            </div>
            <div data-animate="false">
                <h2 className="font-39 font-800 text-center love_counter1">
                <CountUp start={0} end={250000} duration={2.5} separator="," />+</h2>
                <p className="font-18 font-500 text-center mb-0">Sellers Associated </p>
            </div>
            <div data-animate="false">
                <h2 className="font-39 font-800 text-center love_counter1">
                <CountUp start={0} end={425} duration={2.5} separator="," />+</h2>
                <p className="font-18 font-500 text-center mb-0">Developer Partnered</p>
            </div>
            <div data-animate="false">
                <h2 className="font-39 font-800 text-center love_counter1">
                <CountUp start={0} end={200} duration={2.5} separator="," />+</h2>
                <p className="font-18 font-500 text-center mb-0">Companies Partnered</p>
            </div>
            <div data-animate="false">
                <h2 className="font-39 font-800 text-center love_counter1">
                <CountUp start={0} end={5720} duration={2.5} separator="," />+</h2>
                <p className="font-18 font-500 text-center mb-0">Support Empowered</p>
            </div>
        </div>
        </div>
    </>
  )
}

export default Countblock;
