import React from 'react';

//import speakingImg from '../../images/profiles/22.png';
import speakingImg from '../../images/profiles/21.jpeg';
import thinkdifferentImg from '../../images/think-different.png';
import gullyboyImg from '../../images/gully-boy.png';


    const Blogitems = () => {
  return (
    <>
      <div className="item">
            <div className="insightBlock">
                <div className="insightBlockImg">
                    <a href="/">
                        <img src={speakingImg} className="img-fluid" width="100%" alt=""/>
                    </a>
                    <span className="badge">Latest Movie</span>
                </div>
                <div className="insightBlockBrife">
                    <h5><a href="/"></a></h5>
                    <p></p>
                </div>
            </div>
        </div>
                    {/* <div className="item">
                        <div className="insightBlock">
                            <div className="insightBlockImg">
                                <a href="/">
                                    <img src={thinkdifferentImg} className="img-fluid" width="100%" alt=""/>
                                </a>
                                <span className="badge school-marketing">SCHOOL MARKETING</span>
                            </div>
                            <div className="insightBlockBrife">
                                <h5><a href="/">Think different.</a></h5>
                                <p>Nov 14, 2022</p>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="item">
                        <div className="insightBlock">
                            <div className="insightBlockImg">
                                <a href="/">
                                    <img src={gullyboyImg} className="img-fluid" width="100%" alt=""/>
                                </a>
                                <span className="badge alumni-impact">ALUMNI IMPACT</span>
                            </div>
                            <div className="insightBlockBrife">
                                <h5><a href="/">The Real Gully Boy</a></h5>
                                <p>Feb 17, 2023</p>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="item">
                        <div className="insightBlock">
                            <div className="insightBlockImg">
                                <a href="/">
                                    <img src={speakingImg} className="img-fluid" width="100%" alt=""/>
                                </a>
                                <span className="badge school-marketing">SCHOOL MARKETING</span>
                            </div>
                            <div className="insightBlockBrife">
                                <h5><a href="/">Speaking the ‘Parent Language’</a></h5>
                                <p>Feb 17, 2023</p>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="item">
                        <div className="insightBlock">
                            <div className="insightBlockImg">
                                <a href="/">
                                    <img src={thinkdifferentImg} className="img-fluid" width="100%" alt=""/>
                                </a>
                                <span className="badge school-marketing">SCHOOL MARKETING</span>
                            </div>
                            <div className="insightBlockBrife">
                                <h5><a href="/">Think different.</a></h5>
                                <p>Nov 14, 2022</p>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="item">
                        <div className="insightBlock">
                            <div className="insightBlockImg">
                                <a href="/">
                                    <img src={gullyboyImg} className="img-fluid" width="100%" alt=""/>
                                </a>
                                <span className="badge alumni-impact">ALUMNI IMPACT</span>
                            </div>
                            <div className="insightBlockBrife">
                                <h5><a href="/">The Real Gully Boy</a></h5>
                                <p>Feb 17, 2023</p>
                            </div>
                        </div>
                    </div> */}
    </>
  )
};

export default Blogitems;

