import React from 'react';

import FacilitiesImg1 from '../../../images/mask-group-18964.png';
import FacilitiesImg2 from '../../../images/mask-group-18965.png';
import FacilitiesImg3 from '../../../images/a4c9e56d8da4dc9689c762b08a6ee190@2x.png';



import VideoImgModalPopup from '../../VideoImgModalPopup';

import SchoolWebsiteImg from '../../../images/group-40768.png';

import KidschoolImg5 from '../../../images/group-40769.png';

import DummyAddImg from '../../../images/added-img.png';
import DummyAddImgLg from '../../../images/added-img-lg.png';

import YouTubeThumb from '../../../images/black-banner.png';

import RecentImgThumba1 from '../../../images/rectangle-12885.png';
import RecentImgThumba2 from '../../../images/rectangle-12887.png';
import RecentImgThumba3 from '../../../images/rectangle-13499.png';
import RecentImgThumba4 from '../../../images/rectangle-13500.png';
import TwoColForm from '../../TwoColForm';

function BlogTemplate3() {
  return (
    <>
        <div className="mainbanner thinkBanner">
            
            <span className="caption">
                Think Differently
                <span className="sub-caption">Ever wondered how brands such as Apple came up with their tagline?</span>
                <span className="date">Aug 3, 2022</span>
            </span>
            <div className="scrolldown d-flex justify-content-center align-items-center"><b className="float_animate">#</b></div>
        </div>

        <div className="bg-gray-ed container-fluid py-100 pt-5">
            <div className="container max-1140 highlightSection p-lg-0">
                <div className="row">
                    <div className="col-md-12 page-title mb-3 mb-sm-5">
                        <h1 className="title mb-2 mb-sm-4">Schools Usually Highlight Their<br className="d-none d-md-block"/> FACILITIES &amp; OFFERINGS</h1>
                    </div>
                    <div className="col-md-12 ">                        
                        <div className="img-block-wrap">
                            <div className="left-pane">
                                <div className="img-block"><a href="javascript:;" className="imglink" data-img="553px * 744px"><img src={FacilitiesImg1} width="100%"/></a></div>
                            </div>
                            <div className="right-pane">
                                <div className="right-pane-img-block">
                                    <div className="img-block"><a href="javascript:;" className="imglink" data-img="553px * 352px"><img src={FacilitiesImg2} width="100%"/></a></div>
                                </div>
                                <div className="right-pane-img-block">
                                    <div className="img-block"><a href="javascript:;" className="imglink" data-img="553px * 352px"><img src={FacilitiesImg3} width="100%"/></a></div>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>

        <div className="bg-white container-fluid py-100 parter-schools">
            <div className="container mx-auto">
                <h2>Our Partner Schools Think Differently.<br className="d-none d-md-block"/> They Show Their <span>OUTCOMES &amp; SUCCESSES.</span></h2>
            </div>
            <div className="row mb-150">
                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <div className="blue_curve">
                                <div className="curve-img" data-img="648px * 496px">
                                    <img src={SchoolWebsiteImg} width="648"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="d-flex justify-content-start align-items-center h-100">
                                <div className="blue_curve_detail">
                                    <h3>By Showcasing it through their Website</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui.</p>
                                    <div className="sub-header">Want to Stand Out From Other Schools</div>
                                    <a href="javascript:;" className="btn-download-green" data-bs-toggle="modal" data-bs-target="#downloadBrochure"> <i className="icon-calender"></i>Book A Demo</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-12">
                    <div className="row">                        
                        <div className="col-lg-6 ">
                            <div className="d-flex justify-content-end align-items-center h-100">
                                <div className="blue_curve_detail">
                                    <h3>By Showcasing it through their Website</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui.</p>
                                    <div className="sub-header">Want to Stand Out From Other Schools</div>
                                    <a href="javascript:;" className="btn-download-green" data-bs-toggle="modal" data-bs-target="#downloadBrochure"> <i className="icon-calender"></i>Book A Demo</a>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 mb-5 mb-lg-0 order-first order-lg-last">
                            <div className="blue_curve right">
                                <div className="curve-img" data-img="452px * 600px">
                                    <img src={KidschoolImg5} width="452"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid impact-created">
            <div className="container max-1140 mx-auto p-lg-0">
                <h2>Stats on impact created by us</h2>

                <div className="x-scroll">
                    <div className="impact-created-wrap">
                        <ul>
                            <li>
                                <b>54<span>%</span></b>
                                <p>increment in admissions from alumni impact program</p>
                            </li>
                            <li>
                                <b>86<span>%</span></b>
                                <p>increment in University admissions from CGS program</p>
                            </li>
                            <li>
                                <b>42<span>%</span></b>
                                <p>increment in University admissions from CGS program</p>
                            </li>
                        </ul>
                    </div>
                </div>               
            </div>
        </div>   

        <div className="container-fluid">
            <div className="row fold-wrap">

                <div className="fold-section p-0">
                
                    <div className="fold-section-ui">                        
                        <div className="row">
                            <div className="col-md-6 text-center" data-img="550px * 306px">
                                <img className="img-fluid" src={DummyAddImg} width="550"/>
                            </div>
                            <div className="col-md-6">
                                <div className="videoSection blackBanner mt-5 mt-md-0 d-flex justify-content-center">
                             
                              <VideoImgModalPopup className="video-btn" videoId="w7f7A8IkGsE" banner={YouTubeThumb} imgWidth={523}/>   
                                                     
                          </div>
                            </div>
                            <div className="col-md-12 mt-4">
                                <h1>Heading Goes Here</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus…</p>
                                <ul className="listed-points">
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur.</li>
                                </ul>
                                <a className="btn-green" href="javascript:;" data-bs-toggle="modal" data-bs-target="#downloadForm">CTA BUTTON</a>
                            </div>
                            
                        </div>
                    </div>
                  </div>

                  <div className="fold-section p-0">
                
                    <div className="fold-section-ui">
                        <div className="row">
                            <div className="col-md-6 mb-4 mb-md-0 text-center" data-img="550px * 450px">
                                <img src={DummyAddImgLg} className="img-fluid" width="550"/>
                            </div>
                       
                            <div className="col-md-6">
                                <h1>Heading Goes Here</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus…</p>
                                <ul className="listed-points">
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur.</li>
                                </ul>
                                <a className="btn-green" href="javascript:;" data-bs-toggle="modal" data-bs-target="#downloadForm">CTA BUTTON</a>
                            </div>
                            
                        </div>
                    </div>
                  </div>

            </div>
        </div>

        <div className="bg-gray-ed formSection">
            <div className="container max-1140 mx-auto p-lg-0 position-relative" id="formSection">
                <h4 className="form-tite">CONNECT WITH US<br className="d-none d-sm-block"/> FOR YOUR SCHOOL STORIES</h4>
                <div className="body-form-wrap">
                    <div className="body-form">
                        <TwoColForm/>
                    </div>
                </div>
            </div>
        </div>

        <div className="bg-gray-ed recentSuccessStories ">
            <div className="container max-1140 mx-auto px-lg-0 py-5">
                <h2>Recent Success Stories</h2>
                <div className="recentSuccessStoriesWrap">
                    <div className="row g-4">
                        <div className="col-md-6">
                            <div className="recentSuccessStories-bucket">
                                <div className="success-img" data-img="200px * 140px">
                                    <img src={RecentImgThumba1}/>
                                </div>
                                <div className="success-detail">
                                    <h4>Educators, Master the Art of Online Teaching with “Teach Online”</h4>
                                    <span className="date">Aug 3, 2022</span>
                                </div>
                                <a href="javascript:;" className="recentLink"></a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="recentSuccessStories-bucket">
                                <div className="success-img" data-img="200px * 140px">
                                    <img src={RecentImgThumba2}/>
                                </div>
                                <div className="success-detail">
                                    <h4>Let The Prep Begin! 6 Tips to Ace Your College Admission Interview!</h4>
                                    <span className="date">Aug 3, 2022</span>
                                </div>
                                <a href="javascript:;" className="recentLink"></a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="recentSuccessStories-bucket">
                                <div className="success-img" data-img="200px * 140px">
                                    <img src={RecentImgThumba3}/>
                                </div>
                                <div className="success-detail">
                                    <h4>Educators, Master the Art of Online Teaching with “Teach Online”</h4>
                                    <span className="date">Aug 3, 2022</span>
                                </div>
                                <a href="javascript:;" className="recentLink"></a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="recentSuccessStories-bucket">
                                <div className="success-img" data-img="200px * 140px">
                                    <img src={RecentImgThumba4}/>
                                </div>
                                <div className="success-detail">
                                    <h4>What It Will Take For Your Winning School Election Next Year!</h4>
                                    <span className="date">Aug 3, 2022</span>
                                </div>
                                <a href="javascript:;" className="recentLink"></a>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                        <a className="btn-viewmore" href="javascript:;">View More <i></i></a>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default BlogTemplate3;
