import React from 'react';


import ImgArtist1 from '../../images/profiles/01.jpg';
import ImgArtist2 from '../../images/profiles/02.jpg';
import ImgArtist3 from '../../images/profiles/03.jpeg';
import ImgArtist4 from '../../images/profiles/04.jpeg';
import ImgArtist5 from '../../images/profiles/05.jpg';
import ImgArtist6 from '../../images/profiles/06.jpg';
import ImgArtist7 from '../../images/profiles/07.jpg';
import ImgArtist8 from '../../images/profiles/08.jpg';
import ImgArtist9 from '../../images/profiles/09.jpg';
import ImgArtist10 from '../../images/profiles/10.jpg';
import ImgArtist11 from '../../images/profiles/11.jpg';
import ImgArtist12 from '../../images/profiles/12.jpg';
import ImgArtist13 from '../../images/profiles/13.jpg';
import ImgArtist14 from '../../images/profiles/14.jpg';
import ImgArtist15 from '../../images/profiles/15.jpg';
import ImgArtist16 from '../../images/profiles/16.jpg';
import ImgArtist17 from '../../images/profiles/17.jpg';
import ImgArtist22 from '../../images/profiles/22.jpeg';
import ImgArtist23 from '../../images/profiles/23.jpeg';
import ImgArtist24 from '../../images/profiles/24.jpeg';
import ImgArtist25 from '../../images/profiles/25.jpeg';



// import ImgArtist2 from '../../images/R_F00177-1.jpg';
// import ImgArtist3 from '../../images/439A3982-1.jpg';
// import ImgArtist4 from '../../images/3I0A6550-2.jpg';

function ListedProjectsItems() {
  return (
    <>
        <div className="item">
           <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist1} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
           </div>
        </div>
        <div className="item">
            <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist2} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
           </div>
        </div>
        <div className="item">
        <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist3} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
           </div>
        </div>
        <div className="item">
        <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist4} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
           </div>
        </div>
        <div className="item">
           <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist5} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
           </div>
        </div>
        <div className="item">
        <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist7} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
           </div>
        </div>
        <div className="item">
        <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist8} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
           </div>
        </div>
        <div className="item">
        <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist11} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
           </div>
        </div>
        <div className="item">
        <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist13} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
         </div>
        </div>
        <div className="item">
        <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist14} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
         </div>
        </div>
        <div className="item">
        <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist15} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
         </div>
        </div>
        <div className="item">
        <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist16} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
         </div>
        </div>
        <div className="item">
        <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist17} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
         </div>
        </div>
        
        <div className="item">
            <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist22} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
           </div>
        </div>
        <div className="item">
            <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist23} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
           </div>
        </div>

        <div className="item">
            <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist24} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
           </div>
        </div>
        <div className="item">
            <div className='artist-block'>
                <div className='artist-img overflow-hidden rounded mb-2'><a href='#'><img src={ImgArtist25} alt="Pradnya More" width={310} height={387} /></a></div>
                <h3><a href='#'></a></h3>
                <p><a href='#'></a></p>
           </div>
        </div>
    </>
  )
}

export default ListedProjectsItems;
