import React from 'react';

import OwlCarousel from 'react-owl-carousel';

import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import Blogitems from './Blogitems.tsx';



function Homeslider(props) {
   

console.log(props, " props -> items")
    const options = {
        margin: 20,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: { items: props.mobile, dots: true },
            576: { items: props.tab, dots: true },
            700: { items: props.desktopmd, dots: true },
            1000: { items: props.desktoplg },
        },
    };
  return (
    
    <>

    <OwlCarousel id='insights_carousel' item={props.items}  className="insightsSlider owl-carousel owl-theme" {...options}>
      <Blogitems/>
    </OwlCarousel>

  
                    
     
  

    
    </>
  )
}

export default Homeslider;
